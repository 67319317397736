.proporcao-info-modal table {
  border-collapse: collapse;
  border: 1px solid #ccc;
  width: 100%;
  margin-bottom: 6px;
}

.proporcao-info-modal table th,
.proporcao-info-modal table td {
  border: 1px solid #ccc;
  padding: 2px 3px;
  font-size: 11px;
}

.proporcao-info-modal table thead th {
  background: @primary;
  color: #fff;
  cursor: default;
  font-size: 12px;
}

.proporcao-info-modal table tbody tr:nth-child(2n) td {
  background-color: #fbfbfb;
}
@primary-color: #0f73b9;@primary: #0f73b9;@primaryDark: #09609c;@borderColor: #D9D9D9;@headerHeight: 75px;